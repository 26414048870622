import { Component, Input, OnInit } from '@angular/core';
import { AppointmentDateTime, IImedClaimServiceDTO } from '../../../model/interfaces/custom/service-detail-dto';
import moment from 'moment';
import { serviceIdsToHideExamLocationField } from '../imed-claim-service.service';

@Component({
    selector: 'app-imed-claim-service-basic-info',
    templateUrl: './imed-claim-service-basic-info.component.html',
})
export class ImedClaimServiceBasicInfoComponent implements OnInit {
    @Input() imedClaimService: IImedClaimServiceDTO;
    formattedAppointmentDateTime: string;
    constructor() {}

    ngOnInit(): void {
        if (this.imedClaimService.AppointmentDateTime) {
            this.formattedAppointmentDateTime = this.formatAppointmentDateTime(this.imedClaimService.AppointmentDateTime);
        }
    }

    formatAppointmentDateTime(appointmentDateTime: AppointmentDateTime): string {
        return moment.tz(appointmentDateTime.DateTime, appointmentDateTime.Timezone).format('LLL');
    }

    shouldHideExamLocationField(): boolean {
        return serviceIdsToHideExamLocationField.includes(this.imedClaimService.ServiceTypeId);
    }
}
