export enum ServiceIdEnums {
    Record_Retrievals_HIPAA_Processing = 1,
    IME = 2,
    Film_Review = 3,
    Record_Review = 4,
    Billing_Desk_Review = 5,
    Full_Day_Testimony = 6,
    Half_Day_Testimony = 7,
    Deposition = 8,
    Conference_Call_Between_Attorney_Physician_Or_Expert = 9,
    IME_Re_Examination = 10,
    IME_Addendum_Additional_Records = 11,
    IME_Expert_Consultation = 12,
    IME_Conference_Call = 13,
    IME_WC = 14,
    IME_Auto = 15,
    WC_Record_Review = 16,
    AUTO_Record_Review = 17,
    WC_IME_Re_Examination = 18,
    WC_Film_Review = 19,
    WC_Functional_Capacity = 20,
    AUTO_Peer_Review = 21,
    WC_Addendum = 22,
    WC_Conference_Call = 23,
    Auto_Radiology_Review = 24,
    Auto_Addendum = 25,
    Auto_Conference_Call = 26,
    Auto_Re_Exam = 27,
    Auto_NF_10 = 28,
    Subpoena_Processing = 29,
    Independent_Film_Review = 31,
    WC_Independent_Film_Review = 32,
    Auto_Independent_Film_Review = 33,
}
