import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { entityListModuleConfig } from '../../../common/shared.module';
import { ImedClaimServicesEntityListConfig } from './imed-claim-services.entity-list-config';
import { IService } from '@model/interfaces/service';
import { forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { ImedClaimServiceService } from '../imed-claim-service.service';
import { ServiceService } from '@app-shared/services/service.service';
import { ServiceIdEnums } from '../../../common/Enums/service-id.enums';
import { Router } from '@angular/router';
import { ServiceStatusService } from '../servicestatus.service';
import { StatusMapping } from '../imed-claim-service-status-mapping';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';
import { DatePipe } from '@angular/common';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-imed-claim-services',
    styleUrls: ['./imed-claim-services.less'],
    templateUrl: './imed-claim-services.component.html',
})
export class ImedClaimServiceListComponent implements OnInit, OnDestroy {
    searchControl = new UntypedFormControl();
    imedClaimServices: IViewGetServicesForList[];
    currentPage = 1;
    query = '';
    total: number;
    imedClaims: MtSearchFilterItem[] = [];
    radiologySubService = false;
    includeAllServices = false;
    entityListConfig = new ImedClaimServicesEntityListConfig();
    order = 'AppointmentDateTime';
    orderDirection = 'desc';
    serviceTypes: MtSearchFilterItem[] = [];
    services: IService[];
    searchClaimServicesForm: UntypedFormGroup;
    subscriptions: Subscription = new Subscription();
    selectedServices: IService[];
    currentSearch: SearchParams;
    searchChanged: boolean;
    commonSearchParams: SearchParams;
    imedClaim: IImedClaim;
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;
    imedClaimId: number;
    groupedStatusIds: any;
    allServiceStatuses: MtSearchFilterItem[] = [];
    filteredServiceStatuses: MtSearchFilterItem[] = [];
    appointmentEntityName = 'Appointment Date';
    appointmentStartDate: Date = null;
    appointmentEndDate: Date = null;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private serviceService: ServiceService,
        private router: Router,
        private serviceStatusService: ServiceStatusService,
        private datePipe: DatePipe,
        private notificationService: NotificationsService,
    ) {}

    resetFilters(): void {
        this.searchControl.reset();
        this.searchBar.clearSearch();
        this.serviceTypes.forEach((x) => (x.Selected = false));
        this.filteredServiceStatuses.forEach((x) => (x.Selected = false));
        this.appointmentStartDate = null;
        this.appointmentEndDate = null;
        if (this.includeAllServices) {
            this.searchClaimServicesForm.get('searchIncludeAllServices').setValue(false);
        }
        if (this.radiologySubService) {
            this.searchClaimServicesForm.get('groupRadiologySubService').setValue(false);
        }
    }

    ngOnInit(): void {
        this.exportServiceList();

        this.searchClaimServicesForm = new UntypedFormGroup({
            searchIncludeAllServices: new UntypedFormControl(false),
            groupRadiologySubService: new UntypedFormControl(false),
        });

        forkJoin(this.serviceService.getServiceTypeSortedList(), this.serviceStatusService.getGroupedStatusIds()).subscribe(
            ([serviceTypes, statusIds]) => {
                this.serviceTypes = serviceTypes;
                this.groupedStatusIds = statusIds;
                this.allServiceStatuses = statusIds.map((status: IMetaItem) => new MtSearchFilterItem(new MetaItem(status.Id, status.Name), false));
                this.getImedClaimServices();
                this.subscribeToValueChanges();
            },
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    exportServiceList(): void {
        this.entityListConfig.export = {
            exportName: 'Services List',
            getDataForExport: () =>
                this.getImedClaimServicesSubscription(true).pipe(
                    map((x) => {
                        const imedClaimServices = x.body;

                        // Apply the service status mapping logic
                        imedClaimServices.forEach((imedClaimService) => {
                            const masterStatusId = imedClaimService.MasterServiceStatusId as number;
                            const statusName = StatusMapping.get(masterStatusId);

                            if (statusName) {
                                imedClaimService.ServiceStatus = statusName;
                            }
                        });
                        return imedClaimServices;
                    }),
                ),
        };
    }

    overrideDebounceTime(searchControl: UntypedFormControl): void {
        this.searchControl = searchControl;
        this.subscriptions.add(
            searchControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
                this.search(value);
            }),
        );
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private getSelectedServiceStatuses(filterObj: MtSearchFilterItem[]): string {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => this.groupedStatusIds[item.Item.Id].Name.trim())
            .filter((val, i, self) => self.indexOf(val) === i)
            .join('|');
    }

    appointmentDateSelectionChanged(evt): void {
        this.appointmentStartDate = evt.startDate;
        this.appointmentEndDate = evt.endDate;
        this.clearCommonSearchParamsAndSearch();
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);
        const selectedServiceStatuses: string = this.getSelectedServiceStatuses(this.filteredServiceStatuses);

        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeAllServices',
                value: this.includeAllServices ? '1' : '0',
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceStatuses',
                value: selectedServiceStatuses,
            }),
        );

        if (this.appointmentStartDate && this.appointmentEndDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'AppointmentStartDate',
                    value: this.datePipe.transform(this.appointmentStartDate, 'M/d/yyyy', 'UTC'),
                }),
            );
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'AppointmentEndDate',
                    value: this.datePipe.transform(this.appointmentEndDate, 'M/d/yyyy', 'UTC'),
                }),
            );
        }

        return _extraSearchParams;
    }

    getImedClaimServicesSubscription(skipPaging?: boolean): Observable<any> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        return this.imedClaimServiceService.searchImedClaimServices(0, searchparams);
    }

    // Clear out common search params before getting next results, also resets page to 1  to avoid 0 results
    clearCommonSearchParamsAndSearch(): void {
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
        this.currentPage = 1;
        this.getImedClaimServices();
    }

    getImedClaimServices(): void {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        this.imedClaimServiceService
            .searchImedClaimServices(0, searchparams)
            .pipe(debounceTime(400))
            .subscribe(async (answer) => {
                this.imedClaimServices = answer.body;
                this.MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus();
                if (this.radiologySubService) {
                    await this.getGroupedParentChildServices(this.imedClaimServices);
                    this.MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus();
                }
                this.total = +answer.headers.get('X-List-Count');
                this.exportServiceList();
            });
    }

    search(query: string): void {
        this.query = query;
        this.clearCommonSearchParamsAndSearch();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getImedClaimServices();
    }

    private subscribeToValueChanges(): void {
        this.subscriptions.add(
            this.searchClaimServicesForm.get('searchIncludeAllServices').valueChanges.subscribe((value) => {
                this.includeAllServices = value;
                this.clearCommonSearchParamsAndSearch();
            }),
        );

        this.subscriptions.add(
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.searchClaimServicesForm.get('groupRadiologySubService').valueChanges.subscribe(async (value) => {
                this.radiologySubService = value;
                if (this.radiologySubService) {
                    await this.getGroupedParentChildServices(this.imedClaimServices);
                } else {
                    this.clearCommonSearchParamsAndSearch();
                }
            }),
        );
    }

    async getGroupedParentChildServices(services: IViewGetServicesForList[]): Promise<IViewGetServicesForList[]> {
        if (!this.radiologySubService) {
            return services;
        }

        const groupedParentChildServices: IViewGetServicesForList[] = [];

        for (const service of services) {
            groupedParentChildServices.push(service);

            if (service.IsIme && service.HasChildren) {
                const childServicesPromise = await this.getChildServices(service.Id).toPromise();
                const allChildServices = (<any>childServicesPromise).body;

                for (const childService of allChildServices) {
                    if (
                        childService.ServiceTypeId === ServiceIdEnums.Film_Review ||
                        childService.ServiceTypeId === ServiceIdEnums.WC_Film_Review ||
                        childService.ServiceTypeId === ServiceIdEnums.Auto_Radiology_Review
                    ) {
                        const childIndexInResultedServiceList = groupedParentChildServices.findIndex((s) => s.Id === childService.Id);
                        const childIndexInOriginalServiceList = services.findIndex((s) => s.Id === childService.Id);

                        // If child service is already in result, remove it as it will be added after the parent
                        if (childIndexInResultedServiceList !== -1) {
                            groupedParentChildServices.splice(childIndexInResultedServiceList, 1);
                        }

                        // If child service is in original list, remove it to avoid duplication
                        if (childIndexInOriginalServiceList !== -1) {
                            services.splice(childIndexInOriginalServiceList, 1);
                        }

                        // Add the child service after the parent
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        groupedParentChildServices.push(childService);
                    }
                }
            }
        }

        this.imedClaimServices = groupedParentChildServices;
        this.MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus();
        return groupedParentChildServices;
    }

    getChildServices(parentId: number): Observable<IViewGetServicesForList[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ParentServiceId',
                value: parentId.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'AppointmentDateTime',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 100,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.imedClaimServiceService.searchImedClaimServices(0, searchparams);
    }

    imedClaimServiceSelected(event: IItemSelectedEvent): void {
        if (event.entity.IsCaseSettled) {
            return this.notificationService.error('This service is associated with a settled case and cannot be accessed.');
        }
        void this.router.navigateByUrl('/service/' + event.entity.Id);
    }

    MapMasterServiceStatusIdToPhysicianPortalViewableServiceStatus(): void {
        this.imedClaimServices.forEach((imedClaimService) => {
            const masterStatusId = imedClaimService.MasterServiceStatusId;
            const statusName = StatusMapping.get(masterStatusId);

            if (statusName) {
                imedClaimService.ServiceStatus = statusName;
            }
        });
    }

    selectServiceAndFilterStatuses(): void {
        const selectedServiceIds = this.getSelectedFilters(this.serviceTypes);
        this.filteredServiceStatuses = this.allServiceStatuses.filter((status) =>
            this.groupedStatusIds[status.Item.Id].ServiceIds.some((id: number) => selectedServiceIds.indexOf(id) > -1),
        );
        this.filteredServiceStatuses.sort((a, b) => (a.Item.Name < b.Item.Name ? -1 : 1));
        this.clearCommonSearchParamsAndSearch();
    }
}
