export enum ClaimTypes {
    UserRoles = 1,
    Users = 2,
    AddressBooks = 3,
    ManagedList = 4,
    PhysicianFees = 5,
    CaseManagement = 6,
    Tasks = 7,
    DocumentQueue = 8,
    Audit = 9,
    GlobalServiceList = 10,
    GlobalFollowUpList = 11,
    ArchiveAppointment = 12,
    TaskStatusFollowupEngine = 13,
    EmailTemplates = 15,
    CanDeleteServices = 16,
    CanDeleteFollowups = 17,
    MergeCases = 18,
    LetterTemplates = 19,
    AutomatedTemplates = 20,
    DuplicateAddressBooks = 21,
    CanDeleteAmbraStudies = 22,
    MasterFollowUpTypes = 23,
    CanEditFollowupDate = 24,
    MasterServiceStatuses = 25,
    PhysicianCost = 26,
    BulkTaskReassignment = 27,
    BulkTaskCompletion = 28,
    CreateClientVendorInStriven = 29,
    AddClientToClientPortal = 30,
    LoginAsClient = 31,
    RevokeClientAccess = 32,
    ExportAddressBook = 33,
    ExportPhysicianExpertPanelFees = 34,
    BulkDeleteCaseDocuments = 35,
    BulkDeleteServiceDocuments = 36,
    BulkDeleteAddressBookDocuments = 37,
    AddPhysicianExpertToPhysicianExpertPortal = 38,
    RevokePhysicianExpertPortalAccess = 39,
    LoginAsPhysicianExpert = 40,
    PhysicianExpertPortalServiceManagement = 41,
    EnableCase = 42,
}
