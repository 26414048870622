import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { AddressDynamicControls, IAddressDynamicControlsParameters } from '@model/form-controls/address.form-controls';
import { IAddress } from '@model/interfaces/address';
import { IAddressType } from '@model/interfaces/address-type';
import { IUser } from '@model/interfaces/user';
import { IState } from '@model/interfaces/state';

export class AddressDynamicConfig<T extends IAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private address: T,
        private addressTypes?: IAddressType[],
        private states?: IState[],
        private createdBies?: IUser[],
        private modifiedBies?: IUser[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IAddressDynamicControlsParameters = {
            createdBies: this.createdBies,
            modifiedBies: this.modifiedBies,
            states: this.states,
        };
        const dynamicControls = new AddressDynamicControls(this.address, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Address1', 'Address2', 'City', 'Zip', 'StateId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
