import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ICreateDocumentDTO } from '../../model/interfaces/custom/create-document.dto';
import { IAttachmentDTO } from '../../model/interfaces/custom/attachment-dto';
import { Observable, Subject } from 'rxjs';
import { IServiceListDTO } from '../../model/interfaces/custom/service-list-dto';
import { IServiceDetailDTO } from '../../model/interfaces/custom/service-detail-dto';
import { ServiceIdEnums } from '../../common/Enums/service-id.enums';

export const serviceIdsToHideExamLocationField = [
    ServiceIdEnums.Auto_Radiology_Review,
    ServiceIdEnums.Film_Review,
    ServiceIdEnums.WC_Film_Review,
    ServiceIdEnums.Record_Review,
    ServiceIdEnums.WC_Record_Review,
    ServiceIdEnums.AUTO_Record_Review,
    ServiceIdEnums.Conference_Call_Between_Attorney_Physician_Or_Expert,
    ServiceIdEnums.IME_Conference_Call,
    ServiceIdEnums.WC_Conference_Call,
    ServiceIdEnums.Auto_Conference_Call,
    ServiceIdEnums.Independent_Film_Review,
    ServiceIdEnums.WC_Independent_Film_Review,
    ServiceIdEnums.Auto_Independent_Film_Review,
];

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceService extends MetaItemService<any> {
    public unsuccessfulUploads: ICreateDocumentDTO[];
    public clearDocumentUploadQueue: EventEmitter<any> = new EventEmitter<any>();
    private _documentsSavedSubject: Subject<IAttachmentDTO[]>;
    public documentsSaved$: Observable<IAttachmentDTO[]>;
    private _documents: ICreateDocumentDTO[] = [];
    private _documentsChangedSubject: Subject<ICreateDocumentDTO[]>;

    // Event emitter to notify attachments component that service is saved
    public saveDocsOnCreate: EventEmitter<number> = new EventEmitter<number>();

    // subscribed to by imed-claim-service-detail component, initiated by notifyDocumentsUpdate() which is called by imed-claim-service-attachments component
    public documentsChanged$: Observable<ICreateDocumentDTO[]>;

    // Event emitter to let basic info component know it can show confirmation message when doc upload process is complete
    public showConfirmation: EventEmitter<void> = new EventEmitter<void>();

    notifyDocumentsUpdate(newDocs: ICreateDocumentDTO[]): void {
        this._documents = newDocs;
        this._documentsChangedSubject.next(this._documents);
    }

    constructor(public http: HttpClient) {
        super('ImedClaimServiceService', 'Service', 'ServiceIds', '/physician-expert/imedclaimservices', http);
        this._documentsChangedSubject = new Subject();
        this.documentsChanged$ = this._documentsChangedSubject.asObservable();
        this._documentsSavedSubject = new Subject();
        this.documentsSaved$ = this._documentsSavedSubject.asObservable();
    }

    searchImedClaimServices(imedClaimId: number, data: SearchParams): any {
        const requestParams = this.getHttpParams(data);
        const requestPayload = {};
        requestParams.keys().forEach(key => {
            requestPayload[key] = requestParams.get(key);
        });
        return this.http.post<IServiceListDTO>(`/physician-expert/imedclaimservices/${imedClaimId}/_searchServices`, requestPayload,{ observe: 'response' });
    }

    getServiceDetail(serviceId: number): any {
        return this.http.get<IServiceDetailDTO>(`/physician-expert/imedclaimservices/${serviceId}/service-detail`, { observe: 'response' });
    }

    afterDocumentsUploaded(uploadedDocs: IAttachmentDTO[]): void {
        this._documentsSavedSubject.next(uploadedDocs);
    }

    getFilmInformationsByImedClaimServiceId(imedClaimServiceId: number, serviceTypeId: number): Observable<IFilmInformationDTO[]> {
        return this.http.get<IFilmInformationDTO[]>(`/physician-expert/imedclaimservices/filmInformations/getByImedClaimServiceId/${imedClaimServiceId}/${serviceTypeId}`);
    }

    requestDelete(filmInformationId: number): Observable<any> {
        return this.http.put<any>(`/physician-expert/imedclaimservices/filmInformations/request-delete`, filmInformationId);
    }
}
